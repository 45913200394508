body{
    background-color: white;
}
.image{
    width: 200px;
    height: 200px;
}
#logo{
    

    display: flex;
    align-items: center;
    justify-content: center; 
    background-image: url(./imgs/Logo.png);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 120%;
    height: 120%;
    margin-top: 20%;
    margin-left: -20%;
    
}

