
#form{
    display: flex;
    align-items: center;
    justify-content:center;
    margin: auto; 
    width: 50%;
    height: 200px;
    
}
#maintext {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 30px;
    text-align: center;
    z-index: 998;
    padding: 5%;
  }
  
  #subtext {
    position: absolute;
    top: 21%;
    left: 50%;
    transform: translate(-50%, 50%);
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 15px;
    text-align: center;
    z-index: 998;
  }
#mainimage{
    position: relative;
    display: flex;
    align-items: center;
    justify-content:center;
    margin: auto;
    background-image: url(./imgs/stripes.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100vw;
    height: 50vh;
    z-index: 1;
   

}
#dropdown{
    background-color: #e4e3e3;
    margin: auto;
}
#fullwidth{
    border-radius: 10%;
    margin-top: auto;
 
}
#customerform{

   
    justify-content: center;
    align-items: center;
    
}
#forminput{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 998;
}
#sub{
    display: flex;
    justify-content: center;
    align-items: center;
}
#result{

    font-size: 18px;
    font-weight: bold;
    color: purple;
}